






















































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import DateRangeFilter from '@/components/date-range-filter.vue';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import ClientManagementModel from '@/models/crm/client-management.model';
import ClientManagementService from '@/services/crm/client-management.service';
import JustificationManagementModel from '@/models/crm/justification-management.model';
import JustificationManagementService from '@/services/crm/justification-management.service';
import ReasonManagementModel from '@/models/crm/reason-management.model';
import { DateHelper } from '@/utils/helpers/date-helper';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import DatePickerField from '@/components/date-picker-field.vue';
import Field from '@/components/field.vue';

type DataGridFilterConfig = {
  keyword: string | undefined;
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    DatePickerField,
    Field,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
  },
})
export default class CrmClientsManagement extends Vue {
  @inject(InjectionIdEnum.CrmClientManagementService)
  private clientManagementService!: ClientManagementService;

  @inject(InjectionIdEnum.JustificationManagementService)
  private justificationManagementService!: JustificationManagementService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  dialogConfig: IKeyValue<IDialogConfig> = {
    details: {
      show: false,
    },
  };

  model = {
    justificativa: new JustificationManagementModel(),
  };

  grid: GridReadyEvent | null = null;

  selectedCollectionId!: number;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'managementName', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.justify')}`,
        autoHeight: true,
        flex: 0.32,
        cellRenderer: (param): string => {
          let ret = '';
          if (param.data.reason != null && param.data.managerJustificationDate == null) {
            ret += '<span><b>PENDENTE</b></span>';
          } else if (param.data.reason != null && param.data.managerJustificationDate != null) {
            ret += '<span><b>JUSTIFICADO</b></span>';
          } else {
            ret += '<span class="v-icon mdi mdi-square-edit-outline theme--light"></span>';
          }
          return ret;
        },
        cellClass: (param) => {
          if (param.data.reason != null) {
            return '';
          }
          return 'cell-grid-link';
        },
        cellStyle: {
          'justify-content': 'center',
        },
        onCellClicked: (event) => {
          if (event.data.reason == null) {
            this.openModalJustificar(event.data.collectionId);
          }
        },
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.managementName')}`,
        field: 'managementName',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.representant')}`,
        field: 'representantName',
        autoHeight: true,
        flex: 1,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.situation')}`,
        field: 'situation',
        maxWidth: 135,
        flex: 0.3,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.reason')}`,
        field: 'reason',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.observation')}`,
        field: 'observation',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.justificationDate')}`,
        field: 'representantJustificationDate',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.justification')}`,
        field: 'representantJustification',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.evaluationDate')}`,
        field: 'managerJustificationDate',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.evaluation')}`,
        field: 'managerJustification',
        autoHeight: true,
        flex: 0.5,
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
  };

  items: ClientManagementModel[] = [];

  selected: ClientManagementModel[] = [];

  motivoOptions: ReasonManagementModel[] = [];

  async mounted(): Promise<void> {
    this.loadItems();
    this.motivoOptions = await this.justificationManagementService.getReasons();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ClientManagementModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ClientManagementModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Gestão de Clientes',
        fileName: ClientManagementService.generateClientsManagementExportFilename(new Date()),
      });
    }
  }

  get filteredItems(): ClientManagementModel[] {
    let filteredItems = this.items;

    if (this.filters.keyword) {
      const columnsToSearch = [
        'managementName',
        'situation',
        'reason',
        'representantName',
        'representantJustification',
        'managerJustification',
      ];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    return filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;

    this.items = await this.clientManagementService.getClientsManagement(this.getClientIdFromRoute());
    this.gridSettings.loading = false;
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }

  get titleModal() {
    const frase = 'Justificativa da gestão de clientes - ';
    return frase + this.activeClient.nomeFantasia;
  }

  openModalJustificar(collectionId: number): void {
    this.selectedCollectionId = collectionId;
    this.dialogConfig.details.show = true;
  }

  onCancel(): void {
    this.dialogConfig.details.show = false;
    this.model.justificativa.motivo = null;
    this.model.justificativa.observacao = null;
  }

  confirm: JustificationManagementModel[] = [];

  async onConfirm(): Promise<void> {
    this.model.justificativa.codCliente = this.activeClient.codCliente;
    this.model.justificativa.codRepres = this.activeClient.codRepres;
    this.model.justificativa.colecao = this.selectedCollectionId;
    const loader = this.$loading.show();
    try {
      this.confirm = await this.justificationManagementService.confirm(this.model.justificativa);
      this.$notify.success(this.$t('crm.view.clientsManagement.modaljustification.success'), 5000);
      this.onCancel();
      this.loadItems();
    } catch (err) {
      this.$notify.error(err && (err as Error).message, 5000);
    } finally {
      loader.hide();
    }
  }
}
